<template>
  <div class="lesson-module__desc">
    <portal-target name="lesson-description"></portal-target>
  </div>
</template>

<script>
export default {
  name: "LessonDescription"
};
</script>
