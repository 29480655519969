<template>
  <div class="mobile-nav-wrapper">
    <LessonNavButton
      @click="$emit('lessonModuleChange')"
      navBtnType="prev"
      :loading="loading"
      :currentModule="currentModule"
    >
    </LessonNavButton>
    <portal-target name="mobile-nav-slot"></portal-target>
    <LessonNavButton
      @click="$emit('lessonModuleChange')"
      navBtnType="next"
      :loading="loading"
      :currentModule="currentModule"
    >
    </LessonNavButton>
  </div>
</template>

<script>
import LessonNavButton from "./LessonNavButton";
export default {
  name: "LessonMobileNav",
  components: { LessonNavButton },
  props: {
    navBtnType: String,
    loading: Boolean,
    currentModule: String
  }
};
</script>
