<template>
  <fragment v-if="availableModules.length > 0 && !loading">
    <div class="button-wrapper" :class="buttonClass">
      <div
        class="lesson__nav"
        :class="navBtnType === 'prev' ? 'lesson__nav--prev' : 'lesson__nav--next'"
        v-if="currentModule !== 'vocabulary' && currentModule !== 'speaking' && currentModule !== 'key_phrases'"
      >
        <button class="lesson__button" @click="handleNavBtnClick">
          <Icon size="lesson-nav" class="vocabulary__icon" :name="getNavIconName" icon-color-name="secondary-color" />
        </button>
      </div>
      <div v-else>
        <portal-target name="lesson-nav-prev" v-if="navBtnType === 'prev'"></portal-target>
        <portal-target name="lesson-nav-next" v-if="navBtnType === 'next'"></portal-target>
      </div>
    </div>
  </fragment>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "LessonNavButton",
  props: {
    navBtnType: String,
    loading: Boolean,
    currentModule: String,
    buttonClass: String
  },
  inject: ["navIconName"],

  computed: {
    getNavIconName() {
      return this.navIconName();
    },
    ...mapState("lessonStore", ["availableModules"]),
    ...mapGetters("lessonStore", ["nextModule", "prevModule"])
  },

  methods: {
    handleNavBtnClick() {
      this.navBtnType === "prev"
        ? this.goToPreviousModule(null, { root: true })
        : this.goToNextModule(null, { root: true });
      this.$emit("lessonModuleChange");
    },
    ...mapActions("lessonStore", ["goToNextModule", "goToPreviousModule"])
  }
};
</script>
