<template>
  <div
    v-if="availableModules.length > 0"
    style="height: 100%"
    class="center-container--flex"
    :class="['is-module-' + currentModule]"
  >
    <div ref="header" class="center-container__top" :class="{ 'is-sticky': isMobileView }">
      <Header v-if="!isMobileView"></Header>
      <HeaderMobile :module="currentModule" @headerLoaded="onHeaderLoaded"></HeaderMobile>
    </div>
    <div v-if="currentModule !== 'summary'">
      <LessonDescription />
    </div>
    <div v-if="lessonLoaded" class="center-container__middle">
      <div class="lesson-wrapper" v-if="currentModule && !loading">
        <div v-if="lessonNavVisible">
          <LessonNavButton
            navBtnType="prev"
            :loading="loading"
            :currentModule="currentModule"
            buttonClass="lesson-desktop-nav"
            @lessonModuleChange="handleLessonNavVisibility(false)"
          >
          </LessonNavButton>
        </div>
        <component
          ref="currentModule"
          :header="$refs.header"
          :is="getModuleComponent"
          @lessonModuleLoaded="handleLessonNavVisibility(true)"
        />
        <div v-if="lessonNavVisible">
          <LessonNavButton
            navBtnType="next"
            :loading="loading"
            :currentModule="currentModule"
            buttonClass="lesson-desktop-nav"
            @lessonModuleChange="handleLessonNavVisibility(false)"
          >
          </LessonNavButton>
        </div>
      </div>
      <Loading v-else></Loading>
      <LessonMobileNav
        @lessonModuleChange="handleLessonNavVisibility(false)"
        v-if="lessonNavVisible"
        :loading="loading"
        :currentModule="currentModule"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { useMediaQuery } from "@vueuse/core";
import KeyPhrases from "../components/lesson/KeyPhrases";
import Podcast from "../components/lesson/Podcast";
import Vocabulary from "../components/lesson/Vocabulary";
import Speaking from "../components/lesson/Speaking";
import Summary from "../components/lesson/Summary";
import Loading from "../components/Loading";
import LessonNavButton from "../components/LessonNavButton";
import LessonMobileNav from "../components/LessonMobileNav";
import LessonDescription from "../components/global/LessonDescription.vue";

const camelCase = require("camelcase");

export default {
  name: "Lesson",
  components: { LessonDescription, LessonMobileNav, LessonNavButton },
  metaInfo: {
    title: "Lesson"
  },
  data() {
    return {
      isPathLoaded: false,
      navIconName: "",
      mainAreaMarginTop: 0
    };
  },
  provide() {
    return {
      navIconName: () => this.navIconName
    };
  },
  props: {
    loading: Boolean
  },
  async mounted() {
    const { lessonId, attemptId } = this.$route.params;

    if (!lessonId || !attemptId) {
      await this.$router.replace({ name: "Home" }).catch(() => {});
      return;
    }

    await this.getLesson({ lessonId, attemptId });
    this.setCurrentModule({ currentModule: this.$route.params.module });
    await this.getAvailableModules();
    this.setLessonLoaded({ loaded: true });
  },
  created() {
    window.addEventListener("resize", () => {
      this.setLessonNavIconTypeByScreenWidth();
    });
    this.setLessonNavIconTypeByScreenWidth();
    window.addEventListener("resize", () => this.calculateMainAreaMarginTop());
  },

  computed: {
    getModuleComponent() {
      const module = camelCase(this.currentModule, { pascalCase: true });
      switch (module) {
        case "Podcast":
          return Podcast;
        case "KeyPhrases":
          return KeyPhrases;
        case "Vocabulary":
          return Vocabulary;
        case "Speaking":
          return Speaking;
        case "Summary":
          return Summary;
        default:
          return Loading;
      }
    },
    isMobileView() {
      return useMediaQuery("(max-width: 479px)").value;
    },
    ...mapState("pathsStore", ["currentPath"]),
    ...mapState("userStore", ["demo", "introJs"]),
    ...mapState("lessonStore", ["availableModules", "currentModule", "lesson", "lessonLoaded"]),
    ...mapGetters("userStore", ["isIntroJs", "getPathID"]),
    ...mapGetters("lessonStore", ["lessonNavVisible"])
  },

  methods: {
    setLessonNavIconTypeByScreenWidth() {
      this.navIconName = window.innerWidth > 799 ? "lesson-nav-btn" : "lesson-nav-btn-mobile";
    },
    handleLessonNavVisibility(lessonNavIsVisible) {
      this.setLessonNavVisibility(lessonNavIsVisible);
    },
    calculateMainAreaMarginTop() {
      if (!document.querySelector(".head--mobile")) return;
      const mobileMenuHeight = document.querySelector(".head--mobile").clientHeight;
      this.mainAreaMarginTop = useMediaQuery("(max-width: 479px)").value ? mobileMenuHeight - 20 : 0;
    },
    onHeaderLoaded() {
      this.calculateMainAreaMarginTop();
    },
    ...mapMutations("lessonStore", [
      "setCurrentModule",
      "setLessonLoaded",
      "isLessonCurrentPath",
      "setLessonNavVisibility"
    ]),
    ...mapActions("lessonStore", ["getAvailableModules", "getLesson"]),
    ...mapActions("pathsStore", ["getPathLessons"]),
    ...mapMutations("pathsStore", ["setLoading"])
  },
  beforeRouteUpdate(to, from, next) {
    next();
    if (to.params.lessonId !== from.params.lessonId) {
      this.$router.go();
    }
  },

  async beforeRouteLeave(to, from, next) {
    await this.getPathLessons({ pathId: this.getPathID });
    this.setLessonLoaded({ loaded: false });
    next();
  },

  beforeDestroy() {
    window.removeEventListener("resize", () => this.setLessonNavIconTypeByScreenWidth());
    window.removeEventListener("resize", () => this.calculateMainAreaMarginTop());
  }
};
</script>

<style lang="scss">
.is-sticky {
  position: sticky;
  top: 55px;
  margin-inline: calc(var(--grid-padding) * -1);
  width: calc(100% + var(--grid-padding) * 2);
  padding-inline: var(--grid-padding);
  background: #fff;
}
</style>
